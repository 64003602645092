import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './EquipmentSpecfication.module.scss';
import Footer from '../Footer/Footer'
import UserService from '../../Services/user-services';
class EquipmentSpecfication extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isLoader: false,
      active: 0,
      value: 0,
      data: [],
      message: false
    }

  };
  componentDidMount() {

    let parmas = {
      id: this.state.value
    }
    UserService.equipmentSpecfication(parmas).then(
      response => {
        // console.log("response==>", response)
        if (response.data.status === 200) {
          this.setState({
            data: response.data.data
          },

            response.data.data.length === 0 ?
              this.setState({
                message: true
              })
              :
              this.setState({
                message: false
              })
          )
        } else {
          console.log('error===>')
        }
      },
      error => {
        console.log('error===>', error)
      }
    );

  }
  pump = (e) => {
    console.log('event==>', e)
    this.setState({
      value: e
    })
    let parmas = {
      id: e
    }
    UserService.equipmentSpecfication(parmas).then(
      response => {
        // console.log("response==>", response)
        if (response.data.status === 200) {
          this.setState({
            data: response.data.data
          },

            response.data.data.length === 0 ?
              this.setState({
                message: true
              })
              :
              this.setState({
                message: false
              })
          )
        } else {
          console.log('error===>')
        }
      },
      error => {
        console.log('error===>', error)
      }
    );
  }

  render() {
    // console.log('Props:', this.props)
    // const { form, formErrors } = this.state;
    return (
      <div className={styles.mains}>
        <div className={styles.main}>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12 text-center'>
                <div className={styles.heading}>
                  <span>EQUIPMENT SPECS</span>
                </div>

              </div>




            </div>

          </div>
          <div className='container-fluid'>
            <div className='row bg-white'>

              <div className={styles.specfication}>

                <div className="" style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                  <nav>
                    <ul className={styles.nav}>
                      <li onClick={(e) => { this.pump(0) }} className={this.state.value === 0 ? styles.active : ""}>All</li>
                      <li onClick={(e) => { this.pump(1) }} className={this.state.value === 1 ? styles.active : ""} >Putzmeister</li>
                      <li onClick={(e) => { this.pump(2) }} className={this.state.value === 2 ? styles.active : ""}>Schwing</li>
                      <li onClick={(e) => { this.pump(3) }} className={this.state.value === 3 ? styles.active : ""}>Other</li>
                    </ul>
                  </nav>
                </div>
                <div className='container'>
                  <div className='row'>
                    {
                      this.state.message === true ?
                        <div>

                          <h3 className='text-center'> No Data Found </h3>                        </div>
                        : ""
                    }
                    {
                      this.state.data.map((data, index) => {
                        console.log("ApiData==>", data)
                        return (

                          <div className='col-lg-3 col-sm-12 p-2' key={index}>
                            <a href={data.Link} target="_blank" className={styles.boxtextpdf}
                              rel="noreferrer">
                              <div className={styles.bgColor}>
                                <div className={styles.boxtext}>
                                  {data.Name}
                                </div>
{ 
                                <div className={styles.boxtextpdf}>

                                  View PDF
                                  <span style={{ marginLeft: "5px" }}>
                                    {/* <img src='/imgs/arrowleft.png' alt='' className={styles.arrowIcon}></img> */}
                                    </span>

                             
                                </div> }

                              </div>
                            </a>
                          </div>

                        )
                      })
                    }





                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    )
  }
}

EquipmentSpecfication.propTypes = {};

EquipmentSpecfication.defaultProps = {};

export default EquipmentSpecfication;
