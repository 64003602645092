import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './PrivacyPolicy.module.scss';
import Footer from '../Footer/Footer'
import UserService from '../../Services/user-services'
import Moment from 'react-moment';
class PrivacyPolicy extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isLoader: false,
      value:"",
      updateData:"",
      loader:false
    }

  };

  componentDidMount(){
    UserService.Privacy().then(
      response => {
      console.log(response)
      if(response.data.status === 200){
        this.setState({
          value:response.data.data.title,
          updateData:response.data.data.updateAt,
        
        },
        ()=>{
          this.setState({
            isLoader:true
          })
        }
        )
      }else{
      
      }
      },
      error => {
       console.log(error)
      }
    );

  }

  render() {
    // console.log('Props:', this.props)
    // const { form, formErrors } = this.state;
    return (
      <div>
          {
            this.state.isLoader === true ?
            <div className={styles.mains}>
       
            <div className={styles.main}>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12 text-center'>
                    <div className={styles.heading}>
                      <span>PRIVACY</span>
                    </div>
                  
                  </div>
    
    
    
    
                </div>
                <div className='row'>
                  <div className='col-lg-12 col-sm-12 mb-3'>
                    
                  </div>
                  <div className='col-lg-12 col-sm-12'>
                  <div className={styles.texhSubheading} dangerouslySetInnerHTML={{__html:this.state.value}}>
                    </div>
                  </div>
                </div>
              </div>
    
            </div>
    
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
          :""
          }
      </div>
     

    )
  }
}

PrivacyPolicy.propTypes = {};

PrivacyPolicy.defaultProps = {};

export default PrivacyPolicy;
