import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './RequestForm.module.scss';
import Footer from '../Footer/Footer'
import UserService from '../../Services/user-services';
// import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
class RequestForm extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isLoader: false,
      apiData: {},
      issetImage: "",
      selectId: 1,
      cssClassCall: 'imageProdutborder',
      similarProduct: [],
      show: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        pumpId: "",
        message: "",

      },
      formErrors: {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        pumpId: "",
        message: "",
      }

    };
  }
  handleChange = e => {
    const { name, value, checked } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};
    if (name === "language") {
      // handle the change event of language field
      if (checked) {
        // push selected value in list
        formObj = { ...form };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...form,
          [name]: form[name].filter(x => x !== value)
        };
      }
    } else {
      // handle change event except language field
      formObj = {
        ...form,
        [name]: value
      };
    }
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      if (name === "password" || name === "confirmPassword") {
        let refValue = this.state.form[
          name === "password" ? "confirmPassword" : "password"
        ];
        const errorMsg = this.validateField(name, value, refValue);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
        if (!errorMsg && refValue) {
          formErrorsObj.confirmPassword = null;
          formErrorsObj.password = null;
        }
      } else {
        const errorMsg = this.validateField(
          name,
          name === "language" ? this.state.form["language"] : value
        );
        formErrorsObj = { ...formErrors, [name]: errorMsg };
      }
      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    // console.log('Name-->',value)
    let errorMsg = null;
    switch (name) {
      case "firstName":
        if (!value) errorMsg = "Please enter First Name.";
        break;
      case "lastName":
        if (!value) errorMsg = "Please enter Last Name.";
        break;
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter valid Email.";
        break;
      case "message":
        if (!value) errorMsg = "Please enter message.";
        break;
      case "mobile":
        if (value === "") {
          errorMsg = ""
        } else if (
          !/^(\+\d{1,3}[- ]?)?\d{8,16}$/.test(
            value
          )
        )
          errorMsg = "Please enter valid Mobile Number.";
        break;
      default:
        break;
    }
    return errorMsg;
  };
  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      if (x === "password" || x === "confirmPassword") {
        refValue = form[x === "password" ? "confirmPassword" : "password"];
      }
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;

    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      UserService.formSubmit(form).then(
        response => {
          console.log("response==>", response)
          this.setState({
            show: true,
            form: {
              firstName: "",
              lastName: "",
              email: "",
              mobile: "",
              pumpId: "",
              message: ""
            }
          })
        },
        error => {
          console.log('error===>', error)
        }
      );
      console.log('NOt Found')
    }


    // console.log("Data: ", form);
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  render() {
    // console.log('Props:', this.props)
    // const { form, formErrors } = this.state;
    return (
      <div className={styles.main}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <div className={styles.heading}>
                <span>REQUEST MORE INFORMATION</span>
              </div>
            </div>

            <Modal show={this.state.show} onHide={this.handleClose}

              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>

                <Modal.Title className={styles.popuptitle}>Sucess </Modal.Title>

              </Modal.Header>

              <Modal.Body className={styles.popupMessage}>Thank you for contacting us. We will get back to you in several business days.</Modal.Body>


              <Modal.Footer className={styles.popUpSubmit}>
                <Button variant="success" onClick={this.handleClose} className={'text-center'}>
                  Ok
                </Button>

              </Modal.Footer>
            </Modal>



            <div className='row m-0 p-0'>
              <div className='col-lg-6 col-sm-12 formrequest'>
                <div className={styles.personalInfo}>
                  <div className='row'>
                    <div className='col-lg-6 col-sm-12 p-2'>
                      <div>
                        <span className={styles.textlabel}>
                          First Name
                        </span>
                        <div className='py-1'>
                          <input type={'text'} className={'form-control'} placeholder={'Enter First Name'} value={this.state.form.firstName} name="firstName" onChange={this.handleChange}
                            onBlur={this.handleChange} />
                          {this.state.formErrors.firstName && (
                            <span className="text-danger">{this.state.formErrors.firstName}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-sm-12 p-2'>
                      <div>
                        <span className={styles.textlabel}>
                          Last Name
                        </span>
                        <div className='py-1'>
                          <input type={'text'} className={'form-control'} placeholder={'Enter Last Name'} value={this.state.form.lastName} name="lastName" onChange={this.handleChange}
                            onBlur={this.handleChange} />
                          {this.state.formErrors.lastName && (
                            <span className="text-danger">{this.state.formErrors.lastName}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 col-sm-12 p-2'>
                      <div>
                        <span className={styles.textlabel}>
                          Email
                        </span>
                        <div className='py-1'>
                          <input type={'text'} className={'form-control'} placeholder={'Enter Email'} value={this.state.form.email} name="email" onChange={this.handleChange}
                            onBlur={this.handleChange} />
                          {this.state.formErrors.email && (
                            <span className="text-danger">{this.state.formErrors.email}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-sm-12 p-2'>
                      <div>
                        <span className={styles.textlabel}>
                          Phone <span className={styles.coloroptional}>
                            (Optional)
                          </span>
                        </span>
                        <div className='py-1'>
                          <input type={'text'} className={'form-control'} placeholder={'Enter Phone'} value={this.state.form.mobile} name="mobile" onChange={this.handleChange}
                            onBlur={this.handleChange} />
                          {this.state.formErrors.mobile && (
                            <span className="text-danger">{this.state.formErrors.mobile}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12 col-sm-12 p-2'>
                      <div>
                        <span className={styles.textlabel}>
                          Pump Id <span className={styles.coloroptional}>
                            (Optional)
                          </span>
                        </span>
                        <div className='py-1'>
                          <input type={'text'} className={'form-control'} placeholder={'Enter Pump ID'} value={this.state.form.pumpId} name="pumpId" onChange={this.handleChange}
                            onBlur={this.handleChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12 col-sm-12 p-2'>
                      <div>
                        <span className={styles.textlabel}>
                          Message
                        </span>
                        <div className='py-1'>
                          <textarea type={'text'} className={'form-control custom-height-text-area'} name="message" placeholder={'Message'} style={{ height: '201px' }} value={this.state.form.message}
                            onChange={this.handleChange}
                            onBlur={this.handleChange} />
                          {this.state.formErrors.message && (
                            <span className="text-danger">{this.state.formErrors.message}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12 col-sm-12'>
                      <button className={styles.btnSubmit}
                        onClick={this.handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-sm-12 formrequest'>
                <div className={styles.perosnal}>
                  <div className={styles.paddingicon}>
                    <div className='d-flex '>
                      <div className={styles.customPersonalInfo}>
                        <div>
                          <span>
                            <img src='/imgs/personalinfolocation.png' alt='location' className={styles.peronsalimagewidht}></img>
                          </span>
                        </div>


                      </div>
                      <div className={styles.paddinghtitle}>
                        <div className='m-0 p-0'>
                          <span className={styles.personaltextheading}>
                            Location
                          </span>
                        </div>
                        <div className='m-0 p-0'>

                          <span className={styles.personaltextsubheading}>
                            Josh Gribble
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.paddingicon}>
                    <a href='Tel:206-730-3853' className='d-flex '>
                      <div className={styles.customPersonalInfo}>
                        <div>
                          <span>
                            <img src='/imgs/earphone.png' alt='location' className={styles.peronsalimagewidht}></img>
                          </span>
                        </div>


                      </div>
                      <div className={styles.paddinghtitle}>
                        <div className='m-0 p-0'>
                          <span className={styles.personaltextheading}>
                            Phone
                          </span>
                        </div>
                        <div className='m-0 p-0'>

                          <span className={styles.personaltextsubheading}>
                            (206) 730-3853
                          </span>
                        </div>
                      </div>

                    </a>
                  </div>
                  <div className={styles.paddingicon}>
                    <a className='d-flex ' href='mailto:info@concretepumpingdepot.com'>
                      <div className={styles.customPersonalInfo}>
                        <div>
                          <span>
                            <img src='/imgs/message.png' alt='location' className={styles.peronsalimagewidht}></img>
                          </span>
                        </div>
                      </div>
                      <div className={styles.paddinghtitle}>
                        <div className='m-0 p-0'>
                          <span className={styles.personaltextheading}>
                            Email
                          </span>
                        </div>
                        <div className='m-0 p-0'>
                          <span className={styles.personaltextsubheading}>
                            <span>
                              info@concretepumpingdepot.com
                            </span>
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    )
  }
}


RequestForm.propTypes = {};

RequestForm.defaultProps = {};

export default RequestForm;
