
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout/Layout'
import Dashboard from './components/Dashboard/Dashboard';
import ProductDetails from './components/ProductDetails/ProductDetails';
import RequestForm from './components/RequestForm/RequestForm'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'
import EquipmentSpecfication from './components/EquipmentSpecfication/EquipmentSpecfication'
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
            <Route path='/' index element={<Dashboard />} />
            <Route path='/*' index element={<Dashboard />} />
            <Route path='/Product-Detail/:id'  index element={<ProductDetails />} />
            <Route path='/Request-Form'  index element={<RequestForm />} />
            <Route path='/Privacy'  index element={<PrivacyPolicy />} />
            <Route path='/Equipment-Specfication'  index element={<EquipmentSpecfication />} />
            
            
           
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
