import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './Dashboard.module.scss';
import UserService from '../../Services/user-services';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Footer from '../Footer/Footer';
import Pagination from "react-js-pagination";
// import { margin } from '@mui/system';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      categoryId: 1,
      subCategoriesValues:0,
      SubCategories:0,
      advance:1,
      equipment: [],
      advanceData:[],
      recentProduct:[],
      btnFilter:false,
      pageIndex: 1,
      pageSize: 10,
      pageRange: 3,
      totalRecords: 0,
     
    };
  }
  componentDidMount() {

    UserService.allquipment().then(
      response => {
        // console.log("response==>", response)
        if (response.data.status === 200) {
          this.setState({
            equipment: response.data.data.CategoryList
  
          },()=>{
           
            if(this.state.equipment.length !== 0){
              this.setState({
                categoryId:response.data.data.CategoryList[0]?.id
              })
            }else{
              this.setState({
                btnFilter:true
              },()=>{
                console.log("first==>",this.state.btnFilter)
              })
            } 
            
          })
        } else {
          this.setState({
            isLoader:false
           })
        }
      },
      error => {
       this.setState({
        isLoader:false
       })
      }
    );
    UserService.brands().then(
      response => {
       console.log(response)
        if (response.data.status === 200) {
          console.log('ApiResponse==>',response.data.data)
          this.setState({
            advanceData: response.data.data,
            advance:response.data.data[0]?.id

          },
         
          )
        } else {
          console.log("NotREponse==>")
        }
      },
      error => {
       console.log(error)
      }
    );
    let parmas={
      brandId:this.state.advance,
      subCategoryId:this.state.SubCategories,
      categoryId:this.state.categoryId,
      pageIndex: this.state.pageIndex,
      pageSize: this.state.pageSize
    }
    UserService.filter(parmas).then(
      response => {
        // console.log("response==>", response)
        if (response.data.status === 200) {
          this.setState({
            recentProduct: response.data.data.Data,
            isLoader:true,
            pageIndex: response.data.data.pageIndex,
            pageSize: response.data.data.pageSize,
            totalRecords: response.data.data.totalRecords,
          })
        } else {
          console.log('error===>')
        }
      },
      error => {
        console.log('error===>',error)
      }
    );

  }
  handleChange = (event) => {
    console.log('E',event.target.value)
    this.setState({ categoryId: parseInt(event.target.value) })
    this.state.equipment.map((item) => {
      if(item.id === event.target.value){
        if(item.catagoryList.length !== 0){
          console.log("this.equipment==>IF")
          this.setState({
            subCategoriesValues:event.target.value,
            SubCategories:item.catagoryList[0].id
          },
         )
        }else{
          this.setState({
            subCategoriesValues:0,
            SubCategories:0
          })
        }
        
      }
      return null
    })
  };
  handleChangeV1 = (event) => {
    console.log('E',event.target.value)
    this.setState({ SubCategories: parseInt(event.target.value) })

  };
  advanceEquipment = (event) => {
    console.log('E',event.target.value)
    this.setState({ advance: parseInt(event.target.value) })

  };
  filter = ()=>{
    console.log("CAllFilter==>Brands==>",this.state.advance);
    console.log("CAllFilter==>SubCategories==>",this.state.SubCategories);
    console.log("CAllFilter==>Categories==>",this.state.categoryId);
    
    let parmas={
      brandId:this.state.advance,
      subCategoryId:this.state.SubCategories,
      categoryId:this.state.categoryId,
      pageIndex: 1,
      pageSize: 10
    }
    UserService.filter(parmas).then(
      response => {
        console.log("response==>", response)
        if (response.data.status === 200) {
         this.setState({
          recentProduct: response.data.data.Data,
          pageIndex: response.data.data.pageIndex,
          pageSize: response.data.data.pageSize,
          totalRecords: response.data.data.totalRecords,
         },

         )
        } else {
          this.setState({
            isLoader:false
          })
        }
      },
      error => {
        console.log(error)
        this.setState({
          isLoader:false
        })
      }
    );
    console.log("CAllFilter==>")
  }
  resetfilter = ()=>{
    console.log("CAllFilter==>Brands==>",this.state.advance);
    console.log("CAllFilter==>SubCategories==>",this.state.SubCategories);
    console.log("CAllFilter==>Categories==>",this.state.categoryId);
    let parmas={
      brandId:1,
      subCategoryId:1,
      categoryId:1,
      pageIndex: this.state.pageIndex,
      pageSize: this.state.pageSize
    }
    UserService.filter(parmas).then(
      response => {
        console.log("response==>", response)
        if (response.data.status === 200) {
         this.setState({
          recentProduct: response.data.data.Data,
          categoryId:1,
          advance:1,
          pageIndex: response.data.data.pageIndex,
          pageSize: response.data.data.pageSize,
          totalRecords: response.data.data.totalRecords,
         })
        } else {
          this.setState({
            isLoader:false
          })
        }
      },
      error => {
        console.log(error)
        this.setState({
          isLoader:false
        })
      }
    );
    console.log("CAllFilter==>")
  }
  handlePageChange = pageNumber => {
    // console.log("Count===>",this.state.count)
    this.setState({
      count: 0
    })

    if (pageNumber !== this.state.pageIndex) {
      let params = {
        pageIndex: pageNumber,
        pageSize: this.state.pageSize,
        brandId:this.state.advance,
        subCategoryId:this.state.SubCategories,
        categoryId:this.state.categoryId,
      }
      UserService.filter(params).then(
        response => {
          console.log(response);


          if (response.data.status === 200) {
            this.setState({
              recentProduct: response.data.data.Data,
              totalRecords: response.data.data.totalRecords,
              currentPage: pageNumber,
              recordPerPage: response.data.data.pageSize,
              pageIndex: response.data.data.pageIndex
            }, () => {
            })
          } else {
            this.setState({
              loader: false
            })
          }


        },
        error => {
          this.setState({
            loader: false
          })
        }
      );
    }

  }
  render() {
   let arr = this.state.equipment.find((el)=>(el.catagoryList.length));
   console.log("VAlue==>",arr)
    return (
      <div>
        {
          this.state.isLoader === true ? 
      <div className='main-wrapper'>
      <div className={styles.banner}>
        <div className='container home-wrapper'>
          <div className="d-flex justify-content-center banner-wrapper flex-wrap">
            <div className={styles.bannerText}>
              <div className={styles.heading}>
              
                <div className={styles.orange}>
                CONCRETE 
                </div>
                <div className={styles.black}>
                  PUMPING DEPOT
                </div>
              </div>
            
              <div className={styles.btn}>
                <Link to ={'/Request-Form'}>
                <button className={styles.btnown}>
                  Get in Touch
                </button>
                </Link>
               
              </div>
            </div>
            <div className={styles.bannerImages}>
              <img src='/imgs/banner1.png' alt='banner' className={styles.bannerImage}></img>
            </div>
          </div>
          <div className={styles.dashboardFilter}>
            <div className={styles.dflex}>
               {
                
                this.state.categoryId!== this.state.subCategoriesValues ?
              <div className='dashboard-filterFull'>
                <div>

                  <FormControl 
                  className={styles.firstTextbox}
                  
                  >
                    <Select
                      className={styles.colorWhite}
                      value={this.state.categoryId}
                      onChange={this.handleChange}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "#03C03C",
                              margin:'0px 6px',
                              color:"white",
                              borderRadius:"4px",
                            },
                            "& .MuiMenuItem-root:hover": {
                              backgroundColor: "#03C03C",
                              margin:'0px 6px',
                              color:"white",
                              borderRadius:"4px",
                            },
                            "& .MuiMenuItem-root.Mui-selected:hover": {
                              backgroundColor: "#03C03C",
                              margin:'0px 6px',
                              color:"white",
                              borderRadius:"4px",
                            }
                          }
                        }
                      }}
                    >
                      {
                        this.state.equipment.map((data, index) => {
                          console.log('Equipment',data)
                          return (
                            <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
              :
              <div className='dashboard-filter'>
              <div>

                <FormControl 
              
              
              
                className={styles.firstTextbox}
                
                >
                  <Select
                    className={styles.colorWhite}
                    value={this.state.categoryId}
                    onChange={this.handleChange}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: "#03C03C",
                            margin:'0px 6px',
                            color:"white",
                            borderRadius:"4px",
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#03C03C",
                            margin:'0px 6px',
                            color:"white",
                            borderRadius:"4px",
                          },
                          "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "#03C03C",
                            margin:'0px 6px',
                            color:"white",
                            borderRadius:"4px",
                          }
                        }
                      }
                    }}
                  >
                    {
                      this.state.equipment.map((data, index) => {
                        console.log('CAtegoreis==>',data)
                        return (
                          <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
              }
              {
                this.state.categoryId === this.state.subCategoriesValues ? 
                <div className='dashboard-filter'>
                {
                  this.state.equipment.map((item,index) => {
                    console.log("SubCategoreis==>",item)
                    return(
                      <div key={index}>
                           {
                             item.id === this.state.categoryId ?  
                              <div key={index}>
                                <FormControl sx={{ m: 1, minWidth: 120, }}
                                    className={styles.firstTextbox}
                                >
                                  <Select
                                   className={styles.colorWhite}
                                    value={this.state.SubCategories}
                                    onChange={this.handleChangeV1}
                                    MenuProps={{
                                      PaperProps: {
                                        sx: {
                                          "& .MuiMenuItem-root.Mui-selected": {
                                            backgroundColor: "#03C03C",
                                            margin:'0px 6px',
                                            color:"white",
                                            borderRadius:"4px",
                                          },
                                          "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "#03C03C",
                                            margin:'0px 6px',
                                            color:"white",
                                            borderRadius:"4px",
                                          },
                                          "& .MuiMenuItem-root.Mui-selected:hover": {
                                            backgroundColor: "#03C03C",
                                            margin:'0px 6px',
                                            color:"white",
                                            borderRadius:"4px",
                                          }
                                        }
                                      }
                                    }}
                                    displayEmpty
                                  >
                                    {
                                        item.catagoryList.map((data, index) => {
                                        console.log('Equipment inEquipment',data)
                                        return (
                                          <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </div>
                            
                            
                            :""
                           } 
                      </div>
                    )
                   
                    // return <></>;
                  })
                }
              </div> 
                :"" 
              }
              {
                this.state.categoryId!==this.state.subCategoriesValues ? 
                <div className='dashboard-filterFull'>
                <div>
                  <FormControl sx={{ m: 1, minWidth: 120 }}
                      className={styles.firstTextbox}
                  >
                    <Select
                     className={styles.colorWhite}
                      value={this.state.advance}
                      onChange={this.advanceEquipment}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "#03C03C",
                              margin:'0px 6px',
                              borderRadius:"4px",
                              color:"white"
                            },
                            "& .MuiMenuItem-root:hover": {
                              backgroundColor: "#03C03C",
                              margin:'0px 6px',
                              borderRadius:"4px",
                              color:"white"
                            },
                            "& .MuiMenuItem-root.Mui-selected:hover": {
                              backgroundColor: "#03C03C",
                              padding:'10px',
                              borderRadius:"4px",
                              color:"white"
                            }
                          }
                        }
                      }}
                    >
                      {
                        this.state.advanceData.map((data, index) => {
                          console.log('Equipment Brand',data)
                          return (
                            <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
                :
                <div className='dashboard-filter'>
                <div>
                  <FormControl sx={{ m: 1, minWidth: 120 }}
                      className={styles.firstTextbox}
                  >
                    <Select
                     className={styles.colorWhite}
                      value={this.state.advance}
                      onChange={this.advanceEquipment}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "#03C03C",
                              padding:'10px',
                              borderRadius:"4px",
                              color:"white"
                            },
                            "& .MuiMenuItem-root:hover": {
                              backgroundColor: "#03C03C",
                              padding:'10px',
                              borderRadius:"4px",
                              color:"white"
                            },
                            "& .MuiMenuItem-root.Mui-selected:hover": {
                              backgroundColor: "#03C03C",
                              padding:'10px',
                              borderRadius:"4px",
                              color:"white"
                            }
                          }
                        }
                      }}
                    >
                      {
                        this.state.advanceData.map((data, index) => {
                          console.log('BRAND==>',data)
                          return (
                            <MenuItem value={data.id} key={index}>{data.name}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
              }
             
              <div className={styles.dashboardfilterbutton} >
                <button className={styles.btnfilter} disabled={this.state.btnFilter} onClick={this.filter}>
                  Filter
                </button>
                {
                  this.state.advance === 1 && this.state.categoryId === 1 ? "" :
                 
                <div className={styles.resetData} onClick={this.resetfilter} >Reset Filter</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <div className='container' style={{marginTop:'80px'}}>
      <div className="row">
            
             <div className='row cardbody'>
               {
                // console.log(this.state.recentProduct)
                this.state.recentProduct.length?
                this.state.recentProduct.map((recentProduct, index) => {
               
                  return (
                    <div className='col-lg-3 col-sm-12 col-md-3 paddingCard' key={index} >
                      <Link to={'/Product-Detail/' + recentProduct.id}>
                        <Card className={styles.card}>
                          <div className={styles.zoomWrapper}>
                            <Card.Img variant="top" src={recentProduct.images[0]} className={styles.imgHover} />
                           
                          </div>
                          {
                              recentProduct.isSold === 1 ?
                              <img src='/imgs/soldrecent.png' alt='soldIcon' className={styles.topsold}/> :""
                            
                            }
                          <Card.Body>
                            <Card.Text className={styles.fwnormal}>
                              {recentProduct.brandName}
                            </Card.Text>
                            <Card.Title className={styles.cardtitle}>{recentProduct.name}</Card.Title>
                            <Card.Title className={styles.cardHeading}>

                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(recentProduct.amount)}
                            {/* {} */}
                            </Card.Title>
                            <hr></hr>
                            <div className="d-flex justify-content-between">
                              
                              <div>
                                <span className={styles.fwnormal}>Year: </span>
                                <span className={styles.s}>{recentProduct.year}</span>
                              </div>
                              <div>
                                <span className={styles.fwnormal}>Condition: </span>
                                <span className={styles.fwBold}>{recentProduct.Conditions}</span>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Link>
                    </div>
                  )
                }):
              
                  <div className='row'>
                  
                    
                  </div>
                
              }
            </div>
            <div>
            <div className='d-flex justify-content-center p-4'>
                      
                      {
                              this.state.totalRecords === 0 ?
                                <span className='fw-bold'>No Data Found</span>
                                :
                                
                                <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={this.state.pageIndex}
                                  itemsCountPerPage={this.state.pageSize}
                                  totalItemsCount={this.state.totalRecords}
                                  pageRangeDisplayed={this.state.pageRange}
                                  onChange={this.handlePageChange.bind(this)}
                                  activeLinkClass={styles.pageColor}
                                />
                            }
                      </div>
              </div>
          </div>
      </div>
      <div className='footer'>
           <Footer/>
         </div>
      </div>
      :""
    }
      </div>
    )
    
  }
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
