import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className={styles.main}>
    <div className={styles.footermain}>
    <ul className={styles.list}>
        <li >
          <Link to = {'/'} className={styles.colorGray}>
          Home
          </Link>
        </li>
        <li>
        <Link to = {'/Request-Form'} className={styles.colorGray}>
        Contact Us
          </Link>
        </li>
        <li>
        <Link to = {'/Privacy'} className={styles.colorGray}>
        Privacy
          </Link>
        
        </li>
    </ul>
    </div>
    <div className={styles.footerCopyright}>
          <span>Concreate pumping depot © <span>
            
              {new Date().getFullYear()} 
            
            </span>, All Rights Reserved</span>
    </div>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
