import React from 'react';
import { Outlet } from "react-router-dom";
import styles from './Layout.module.scss';
import { Link,useLocation } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
const Layout = () => {
  const location = useLocation();
  console.log("Params==>",location)
  return (
    <div>
      <Navbar className={styles.bhheading} expand="lg">
        <Container>
          <div className={styles.mobileLogo}>
            <Navbar className={styles.navaLogo}>
              <Link to={'/'}>
                <img src='/imgs/logochange.png' alt='imgLogo' className={styles.logoWidth}></img>
              </Link>
            </Navbar>
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >

            </Nav>
            <Form className="d-flex">
              <ul className={styles.contactHeight}>
                
               
                  <li className={
                  location.pathname === "/Equipment-Specfication"?  styles.contactColorAcive:
                  styles.contactColor
                  }  >

                    {}
                  <Link to={'/Equipment-Specfication'}>
                  Equipment Specs
                  </Link>
                </li>
                <li className={
                  location.pathname === "/Request-Form"?  styles.contactColorAcive:
                  styles.contactColor
                  }  >

                    {}
                  <Link to={'/Request-Form'}>
                    Contact Us
                  </Link>
                </li>
              </ul>

            </Form>
          </Navbar.Collapse>
        </Container >
      </Navbar>
      <div className='myCollapses'>
        <Outlet />
      </div>
    </div>

    //   <div>
    //   <div className={styles.bhheading}>
    //     <div className='container'>
    //       <div className='row'>
    //         <div className="d-flex justify-content-between">
    //               <div className={styles.logo}>
    //                 <Link to={'/'}>
    //               <img src='/imgs/logochange.png' alt='imgLogo' className={styles.logoWidth}></img>
    //                 </Link>
    //             </div>   
    //             <div >
    //                 <ul className={styles.contactHeight}>
    //                       <li className={styles.contactColor}>Equipment Specs</li>
    //                       <li className={styles.contactColor}>
    //                         <Link to={'/Request-Form'}>
    //                         Contact Us
    //                         </Link>
    //                         </li>
    //                 </ul>
    //               {/* <span className={styles.contactColor}>Contact Us</span>

    //               <span className={styles.contactColor}>Contact Us</span> */}
    //               </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div>

    //     <Outlet />
    //   </div>

    // </div>
  )
}

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
