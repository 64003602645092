import axios from 'axios';
import { Component } from 'react';
// import authHeader from './auth-header';

// const API_URL = "http://localhost:3004/api/v1/"
const API_URL = "https://api.concretepumpingdepot.com//api/v1/"

// console.log = () => {}
class UserService extends Component {

 
 
  static allquipment(){
    return axios.get(API_URL + '/commom/allEquipment',)
  }
  static brands(){
    return axios.get(API_URL + '/commom/allEquipmentBrands',)
  }
  static recentProduct(){
    return axios.post(API_URL + '/commom/recentAddedProduct',)
  }
  static productDetail(id){
    return axios.post(API_URL + '/common/proudctDetail',{
      "id":id
    })
  }
  static similarProduct(){
    return axios.post(API_URL + '/commom/similarProduct',)
  }
  static formSubmit(form){
    return axios.post(API_URL + '/commom/FormSubmit',form)
  }
  static filter(parmas){
      return axios.post(API_URL + '/common/filterData',parmas)
  }
  static equipmentSpecfication(parmas){
    return axios.post(API_URL + '/common/Equipmentspecfication',parmas)
  }
  static Privacy(){
    return axios.get(API_URL + '/common/GetPrivacy')
  }
}


export default UserService;


