import React, { Component } from 'react';

import styles from './ProductDetails.module.scss';
import UserService from '../../Services/user-services';
// import { Link } from 'react-router-dom';
// import Card from 'react-bootstrap/Card';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Footer from '../Footer/Footer';
import withRouter from '../../Common/with-router';
class ProductDetails extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isLoader: false,
      apiData: {},
      issetImage: "",
      selectId: 1,
      cssClassCall: 'imageProdutborder',
      similarProduct: [],
    };
  }

  componentDidMount() {
    UserService.productDetail(this.props.params.id).then(
      response => {
        console.log("response==>", response.data.data[0])
        if (response.data.status === 200) {
          this.setState({
            apiData: response.data.data[0],
         
          }, () => {
            this.setState({ isLoader: true })
          })

          // console.log('Api Response==>',response.data.data)
        } else {
          console.log('error===>')
        }
      },
      error => {
        console.log('error===>', error)
      }
    );

    // console.log(this.props.params.id)
  }
  imageClick(e) {

    console.log('first==>', e)
    this.setState({
      issetImage: e,
      selectId: e.id

    })
  }
  render() {
    // console.log('Props:', this.props)
    return (
      <div>
        {
          this.state.isLoader === true ?
            <div className='main'>
              <div className='container'>
                <div className='row p-0 m-0 '>
                  <h4 className={styles.headingName}>
                    {this.state.apiData.name}
                  </h4>
                  {/* <div>
                    <img src='/imgs/location.png' alt='imgLocation' className={styles.imgLocation}></img>
                    <span className={styles.countryName}>
                      {this.state.apiData.location}
                    </span>
                  </div> */}
                  <div className='row p-0 m-0'>
                    <div className='col-lg-12 m-auto pt-2 col-sm-12'>
                      <div className={styles.imgBorder}>
                        <img src={this.state.issetImage === "" ? this.state.apiData.images[0] : this.state.issetImage} className={styles.productImage} alt="recent"></img>
                        <div className={styles.topsold}>
                          {
                            this.state.apiData.isSold === 1 ?
                              <img src='/imgs/soldrecent.png' alt='soldIcon' className={styles.topsold} /> :
                              ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.multipleImages}>
                    <div className='row'>
                      {
                        this.state.apiData.images.map((data, index) => {
                          // console.log('Equipment inEquipment',data)
                          return (
                            <div className='col-md-1 col-4 p-2' key={index}>
                              <img src={data} alt="imgs" onClick={() => this.imageClick(data)} className={data === this.state.issetImage ? styles.imageProdutborder : styles.imageProdutnotborder}></img>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center flex-wrap '>
                    <div>
                      <div className='d-flex justify-content-between  align-items-center'>
                        <span className={styles.price}>
                        
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd' }).format(this.state.apiData.amount)}
                        </span>
                        {/* <div className={styles.sold}>
                          {
                            this.state.apiData.isSold === 1 ?
                              <img src='/imgs/sold.png' alt='soldimgs' className="w-100"></img>
                              :
                              ""
                          }
                        </div> */}
                      </div>
                    </div>
                    <a href='Tel:206-730-3853' className={styles.btncallUs}>
                      <span>
                        <img src='/imgs/sound.png' alt="sound" className={styles.sound}></img></span>
                        {/* <a href='Tel:206-730-3853'  className={styles.colorWhite} > */}
                        <span className={styles.colorWhite}>
                        CALL US FOR MORE INFO
                        </span>
                          

                    </a>
                  </div>
                  <div className={styles.productdescription}>
                    <hr />
                    <div className='pb-5'>
                      <div className='row justify-content-start'>
                      <div className='col-md-5 m-0 pt-1 col-sm-12'>
                          <div className='row justify-content-start'>
                            <h3 className={styles.descriptionHeading}>
                              Pump Info
                            </h3>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Make:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.make}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Model:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.model}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Year:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.year}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Pump Hours:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.hours}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Serial Number:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.serialNumber}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Sections:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.section}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Fold Style:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.foldStyle}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Radio Remote:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.radioRemote}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Proportional:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.proportional}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Condition:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.Conditions}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Notes:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.pumpInfo.notes}</div>
                              </div>
                            </div>
                          </div>
                          </div>
                          {
                            this.state.apiData.truckInfoStatus === 1 ? 
                            <div className='col-md-5 m-0 pt-1 col-sm-12'>
                          <div className='row justify-content-start'>
                            <h3 className={styles.descriptionHeading}>
                              Truck Info
                            </h3>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Make:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.make}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Model:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.model}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Vin:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.vin}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Engine:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.engine}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Hours:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.hours}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Miles:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.miles}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Transmission:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.transmission}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Manual / Automatic:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.manual}</div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 col-6 m-0 pt-1'>
                                <div className={styles.descriptionfeaturesheading}>Notes:</div>
                              </div>
                              <div className='col-md-6 col-6 m-0 pt-1 '>
                                <div className={styles.descriptionfeatures}>{this.state.apiData.truckInfo.notes}</div>
                              </div>
                            </div>
                            
                            
                            
                          
                          </div>
                          </div>
                            
                            :""
                          }
                          
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div className='footer'>
                <Footer />
              </div>
            </div>
            : ""
        }
      </div>
    )
  }
}

ProductDetails.propTypes = {};

ProductDetails.defaultProps = {};

export default withRouter(ProductDetails);
